/* eslint-disable no-console */
import { Plugin } from 'vue';
import axios from 'axios';
import qs from 'qs';
import { requestToken, convertDates, datesToString } from '@/helpers/Utils';
import isObject from 'lodash/isObject';
import CustomToast from '../views/CustomToast.vue';

const AxiosPlugin: Plugin =
{
    install(app)
    {
        const vue = app.config.globalProperties;

        axios.defaults.baseURL = import.meta.env.VITE_APP_API_URL;
        axios.defaults.withCredentials = true;
        axios.defaults.headers.common = {
            ...axios.defaults.headers.common,
            ...{
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0'
            }
        };
        axios.interceptors.request.use(config =>
        {
            const url = new URL(`${config.baseURL}/${config.url}`);

            config.headers['Accept-Language'] = vue.$i18n.locale() || '';
            config.headers['Request-Token'] = requestToken(url.pathname, vue.$auth.token());

            // if (vue.$auth.token())
            // {
            //     config.headers.Authorization = `Bearer ${vue.$auth.token()}`;
            // }

            if (vue.$auth.impersonating())
            {
                config.headers['Access-Impersonate'] = vue.$auth.impersonatingValue();
            }

            config.paramsSerializer = {
                serialize: (params) =>
                {
                    return qs.stringify(datesToString(params), {
                        arrayFormat: "indices",
                        encode: false
                    });
                }
            };

            return config;
        });
        axios.interceptors.response.use(
            response =>
            {
                if (response && response.headers && response.headers['content-type']?.includes('json') && isObject(response.data))
                {
                    response.data = convertDates(response.data);
                }

                return response;
            },
            error =>
            {
                const ex = {
                    code: 500,
                    message: 'Unexpected exception occured.',
                    data: null as any,
                    inner: error
                };

                if (error.response)
                {
                    ex.code = error.response.status;
                    ex.data = (typeof error.response.data === 'string') ? { message: error.response.data } : error.response.data || {};
                    ex.message = 'message' in ex.data ? ex.data.message : error.message;
                }
                else
                {
                    ex.message = error.message;
                }

                if (ex.code >= 400)
                {
                    switch (ex.code)
                    {
                        /* 401 - Unauthorized */
                        case 401:
                        {
                            if (!error.config.url.startsWith('auth/'))
                            {
                                vue.$auth.logout();
                            }

                            break;
                        }
                        /* 403 - Forbidden */
                        case 403:
                        {
                            const content = {
                                component: CustomToast,
                                props: {
                                    message: vue.$t(
                                        '[[[Nie masz uprawnień do tego widoku. Stan widoku mógł ulec zmianie, odśwież i sprawdź lub spróbuj ponownie później.]]]'
                                    ),
                                    update: vue.$t("[[[Odśwież]]]"),
                                },
                                listeners: {
                                    reload: () => location.reload()
                                }
                            };

                            vue.$alert.debounceShow(content, {
                                type: "warning",
                                timeout: false,
                                closeButton: "button",
                                closeOnClick: true,
                                draggable: false,
                            });

                            break;
                        }
                        /* 404 - Not Found */
                        case 404:
                        {
                            vue.$alert.debounceError(vue.$i18n.translate('[[[Błąd 404. Nie znaleziono żądanego zasobu.]]]'));

                            break;
                        }
                        /* 500 - Internal Server Error
                           503 - Service Unavailable */
                        case 500:
                        case 503:
                        {
                            if (error.message && error.message == "Network Error")
                            {
                                vue.$alert.debounceDanger(vue.$i18n.translate('[[[Wystąpił błąd połączenia. Upewnij się że masz włączony internet i spróbuj ponownie.]]]'));
                            }
                            else if (error.message != 'canceled')
                            {
                                vue.$alert.debounceDanger(vue.$i18n.translate('[[[Wystąpił błąd serwera podczas obsługiwania wybranej operacji. Spróbuj ponownie.]]]'));
                            }

                            if (ex.data && ex.data.stackTrace)
                            {
                                console.log(ex.data.message);
                                console.log(ex.data.stackTrace);
                            }

                            break;
                        }
                        default:
                        {
                            if (ex.code > 500)
                            {
                                vue.$alert.debounceError(vue.$i18n.translate('[[[Wystąpił nieznany błąd serwera podczas obsługiwania wybranej operacji. Spróbuj ponownie.]]]'));
                            }

                            break;
                        }
                    }
                }

                return Promise.reject(ex);
            }
        );

        vue.axios = axios;
        vue.$http = axios;
    }
};

export default AxiosPlugin;
export { axios };
